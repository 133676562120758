<template>
  <v-app>
    <main>
      <v-container
        id="user-profile"
        fluid
        tag="section"
      >
        <br>
        <v-row
          align="center"
          justify="center"
        >
          <img
            :src="logo"
          >
        </v-row>
        <br>
        <v-card
          class="my-base-vcard-style"
        >
          <br>
          <h2 class="title-style">
            <v-icon class="icon-style">
              mdi-account-plus
            </v-icon> User Registration
          </h2>
          <v-card
            class="my-vcard-style"
          >
            <v-container class="py-0">
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <br>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="firstName"
                        label="First Name*"
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="lastName"
                        label="Last Name*"
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <address-form-for-registration
                        ref="addressForm"
                        @addressEntered="assiginAddress($event)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="createPassword"
                        label="Create Password*"
                        :append-icon="showCreatePassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showCreatePassword ? 'text' : 'password'"
                        :rules="createPasswordRules"
                        counter
                        required
                        @click:append="showCreatePassword = !showCreatePassword"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="confirmPassword"
                        label="Confirm Password*"
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                        counter
                        required
                        @click:append="showConfirmPassword = !showConfirmPassword"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="phoneNumber"
                        label="Phone-Number"
                        dense
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="userId.id"
                        class="user-id-style"
                        label="User-Id*"
                        disabled
                        required
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <br>
                <p class="text-sm-left">
                  *indicates required field
                </p>
              </v-card-text>
            </v-container>
            <v-card-actions>
              <v-btn
                class="mt-2"
                color="red darken-3"
                @click="$router.push({ name: 'Login' })"
              >
                Back
              </v-btn>
              <v-spacer />
              <v-btn
                class="mt-2"
                color="#FF3700"
                @click="register()"
              >
                Register
              </v-btn>
            </v-card-actions>
          </v-card>
          <br>
          <br>
        </v-card>
        <dashboard-core-footer />
      </v-container>
    </main>
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { cdnUrl } from 'src/app-globals';
  import AddressFormForRegistration from 'src/views/UserRegistration/AddressFormForRegistration';

  export default {
    name: 'UserRegistrationForm',
    components: {
      'centre-spinner': spinner,
      'address-form-for-registration': AddressFormForRegistration,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
    },
    data () {
      return {
        firstName: '',
        lastName: '',
        address: {},
        phoneNumber: null,
        email: '',
        createPassword: '',
        confirmPassword: '',
        valid: false,
        showCreatePassword: false,
        showConfirmPassword: false,
        loading: false,
        createPasswordRules: [v => (v || '').length >= 8 || 'Not strong.Requires at least 8 characters.'],
        confirmPasswordRules: [v => !!v || 'This field is required'],
        logo: `${cdnUrl}/website/NewDBSLogo.png`,
      };
    },
    computed: {
      linkActiveId () {
        return this.$store.getters['registration/getLinkActiveId'];
      },
      userId () {
        return this.$store.getters['registration/getExternalId'];
      },
      passwordConfirmationRule () {
        return this.createPassword === this.confirmPassword || 'Password must match';
      },
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (Object.keys(vm.linkActiveId).length === 0) {
          vm.$router.push({ name: 'Login' });
        } else {
          next();
        }
      });
    },
    methods: {
      register () {
        this.$refs.addressForm.getEnteredAdress();
        if (this.$refs.form.validate() === false || Object.keys(this.address).length === 0) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('registration/registerNewUser', {
          link_active_id: this.linkActiveId.link_active_id,
          first_name: this.firstName,
          last_name: this.lastName,
          address: this.address,
          phone_number: this.phoneNumber,
          password: this.createPassword,
          password_confirmed: this.passwordConfirmationRule,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'User registered successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.address = {};
          this.$router.push({ name: 'Login' });
        }).finally(
          this.loading = false,
          this.$store.dispatch('registration/clearLinkActiveId'),
          this.$store.dispatch('registration/clearExternalId'),
        );
      },
      assiginAddress (address) {
        this.address = address;
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 15px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
  margin-left: 20px;
  margin-right: 20px;
}
.title-style {
  color: #37474F;
  text-align: center;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.user-id-style {
  margin-top:0px;
  padding-top:0px;
}
</style>
